<template>
    <lassoLayout>
        <div class="page-password-changed full_page">
            <div class="content-page">
                <div class="block_password_changed">
                    <div class="success-icon">
                        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="40" cy="40" r="38" stroke="#20C4F5" stroke-width="4"/>
                            <path d="M24 40L35 51L56 30" stroke="#20C4F5" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <h2>
                        Password Changed
                    </h2>
                    <div class="description">
                        Your password has been updated. Log in using your new password.
                    </div>
                    <button type="button" @click="login" class="c2a_btn medium" v-ripple>
                        <span>Log In</span>
                    </button>
                </div>
            </div>
        </div>
    </lassoLayout>
</template>

<script>
import lassoLayout from "@/layouts/lassoLayout.vue";
export default {
    name: 'password-changed',
    components: {
        lassoLayout
    },
    methods: {
        login() {
            this.$router.push({name: 'login'});
        }
    }
}
</script>

<style lang="scss">
.page-password-changed {
    .content-page {
        margin-top: -300px;
    }
    .block_password_changed {
        .tetriatary_btn {
            min-width: 200px;
        }
    }
}
</style>
